<div class="example-container mat-elevation-z8">
  <h3>{{nickname}} <button mat-flat-button (click)="logout()"><mat-icon>logout</mat-icon></button></h3>
  <h2>Room List</h2>
  <div class="example-loading-shade"
       *ngIf="isLoadingResults">
    <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
  </div>
  <div class="button-row">
    <button mat-flat-button color="primary" [routerLink]="['/addroom']"><mat-icon>add</mat-icon></button>
  </div>
  <div class="mat-elevation-z8">
    <table mat-table [dataSource]="rooms" class="example-table"
           matSort matSortActive="roomname" matSortDisableClear matSortDirection="asc">

      <!-- Room Name Column -->
      <ng-container matColumnDef="roomname">
        <th mat-header-cell *matHeaderCellDef>Room Name</th>
        <td mat-cell *matCellDef="let row">{{row.roomname}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="enterChatRoom(row.roomname)"></tr>
    </table>
  </div>
</div>
